<template>
  <div v-if="true">
    <v-switch :disabled="disabled" :label="`Show ${name}`" v-model="showOptions" hide-details />
    <div v-if="calculatedValue" class="expansion-options">
      <v-text-field 
        v-if="showOptions"
        v-model="calculatedValue.label"
        :label="name" 
        />
      <v-text-field 
        v-model="calculatedValue.hint"
        label="Detailed description of this field" 
        />
      <v-select
        v-model="calculatedValue.type" 
        :items="siteData.reg_field_types"
        item-text="text"
        item-value="type"
        label="Field Type" 
        persistent-hint
        hint="Type of data in this field."
        />
      <v-text-field 
        v-if="calculatedValue.type == 'TEAM'"
        v-model="calculatedValue.team_category"
        label="Team category" 
        hide-details
        />
      <v-combobox
        v-if="calculatedValue.type !== 'TEAM'"
        v-model="calculatedValue.options" 
        label="Available Options" 
        multiple
        chips
        persistent-hint
        hint="Enter the list of allowed options (tab for next item) or leave empty for free text."
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'

export default {
  name: "RegFieldEditor",
  components: {
  },
  props: {
    value: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    name: String,
  },
  data: function() {
    return {
      siteData: siteData,
      showOptions: false,
      localValue: {},
    };
  },
  async mounted() {
    EventBus.$on('login-state-change', async user => {
    });
    this.showOptions = this.value != null;// && this.value.label;
    if (this.value) {
      this.localValue = this.value;
    }
  },
  methods: {

  },
  computed: {
    calculatedValue:{
      get () {
        return this.value;
      },
      set (value) {
        //this.$emit('input', value);
      }
    },  
    showEditor() {
      return this.value != null;
    },
    selectOptionRules() {
      return [
        v => !!v || this.$t('shared.required-option'),
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    value(val) {
      console.log('Open editor for ', this.name, val, this.value);
      this.showOptions = val != null;
      //this.showOptions = val && val.label;
      //this.localValue = this.value;
    },
    showOptions(val) {
      console.log('Show editor for ', this.name, val, this.value, this.localValue);
      if (val) {
        console.log('emitting', this.localValue, 'for field', this.name);
        this.$emit('input', this.value || {});
      }
      else {
        console.log('emitting', null, 'for field', this.name);
        this.$emit('input', null);
      }
      //this.showOptions = val && val.label;
    },
  }
};
</script>

<style lang="scss">

  .expansion-options {
    margin: 0 0 8px 28px;
    padding-left: 16px;
    border-left: solid 2px #ccc;
  }
</style>