<template>
  <v-input v-model="calculatedValue" :prepend-icon="icon" class="mt-0 input-row" :label="label" :rules="rules" :disabled="disabled" :hide-details="!hint" :hint="hint" :persistent-hint="!!hint">
    <template>
      <v-btn-toggle v-model="calculatedValue" :mandatory="mandatory" :multiple="multiple" :disabled="disabled" :rounded="rounded" class="multiline-btns">
        <v-btn v-for="(item, idx) in items" :key="idx" :value="item.type || '_'" :small="small" :disabled="disabled" :title="item.title" :class="{ 'me-1': multiple}">
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>
    </template>
  </v-input>
</template>

<script>
export default {
  name: "ToggleButtonInput",
  components: {
  },
  props: {
    value: { type: null, default: null },
    label: { type: String, default: null },
    hint: { type: String, default: null },
    icon: { type: String, default: null },
    items: { type: Array, default: null },
    rules: { type: Array, default: () => [] },
    small: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
  },
  data: function() {
    return {
      rendered: null,
    };
  },
  async mounted() {
      
  },
  methods: {
      
  },
  computed: {
    calculatedValue:{
      get () {
        //console.log('/// toggle value', this.value, this.hint);
        if (Array.isArray(this.value)) {
          return this.value;
        }
        return !this.value ? '_' : this.value
      },
      set (value) {
        //console.log('/// toggle value changing to', value);
        if (Array.isArray(value)) {
          this.$emit('input', value);
          return;
        }
        let val = value == '_' ? null : value;
        //console.log('/// toggle value changing to', val);
        this.$emit('input', val);
      }
    },      
  },
  watch: {
      
  }
};
</script>

<style lang="scss">
</style>